import * as React from 'react';

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CharismaAlertsList from '../studentTrainings/alertsList.tsx'
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import CharismaStudentTrainingBook from '../studentTrainings/studentTrainingBook.tsx'
import CharismaStudentsTrainingAssessmentsList from '../studentTrainings/studentTrainingAssessmentsList.tsx';
import CharismaStudentTrainingBoard from '../studentTrainings/studentTrainingBoard.tsx'
import CharismaTrainingDocs from '../training/trainingDocs.tsx'
import CharismaScheduler from '../training/scheduler.tsx'
import CharismaStudentDocsToAcceptForm from './docToAcceptForm.tsx'
import CharismaImagesList from '../components/imagesList.tsx';
import CharismaTutorSpaceMenu from './tutorSpaceMenu.tsx';
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography';
import {formatDate} from '../utils.tsx'




export default function CharismaTutorSpace(props){
    

    const [id, setId] = React.useState(1);
    const [course, setCourse] = React.useState(null);
    const [training, setTraining] = React.useState(null);
    const [student, setStudent] = React.useState(null);
    const [financal, setFinancal]= React.useState(null);
    const [running, setRunning]= React.useState(true);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [reason, setReason] = React.useState(0);
    const [docAccepted, setDocAccepted] = React.useState(true);
    const [docsToAccept, setDocsToAccept] = React.useState([]);
    
    const [page, setPage] = React.useState('book');
    
    const lStudentTrainingId = props.studentTrainingId;
     
    React.useEffect(()=>{
        const lResponse = CharismaStudentTrainingApi.GetStudentTraining(lStudentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setId(value.studentTraining.id);
                setStartDate(value.studentTraining.startDate);
                setEndDate(value.studentTraining.endDate);
                setReason(value.studentTraining.reason === null ? 0 : value.studentTraining.reason);
                setRunning(value.studentTraining.running)
                setFinancal(value.studentTraining.financal);
                setStudent(value.studentTraining.student);
                setTraining(value.studentTraining.training);
                setCourse(value.studentTraining.course)
                setDocAccepted(value.studentTraining.acceptedByTutor);
                setDocsToAccept(value.studentTraining.docsToAccept);
            }
        }).catch((error)=>{
            
        })

    }, [lStudentTrainingId])
    
    React.useEffect(()=>{
        setPage(props.page);    
    },[props.page])
    
    const lReasons=["En Cours","Certifié", "Abandon", "Echec"];
    
    function handleAcceptDocs(){
        const lResponse = CharismaStudentTrainingApi.UpdateAccepted(lStudentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setDocAccepted(true);
            }
        }).catch((error)=>{
            
        })        
    }
    
    return(
        
        <Box>
        {student !== null ? 
            <Card>
                <CardHeader
                    avatar={
                        student.avatar !== null ?
                        <Avatar
                            src={student.avatar}
                        />
                        :
                        <Avatar>
                            {student.firstName[0] + student.lastName[0]}
                        </Avatar>
                    }
                    title={
                        <Box>
                            <Typography>{student.firstName + ' ' + student.lastName}</Typography>
                            <Typography>{student.mail}</Typography>
                            <Typography>{student.phone}</Typography>  
                        </Box>
                    }                    
                />
                
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>                        
                        <Typography>{"Date d'entrée : " + formatDate(startDate)}</Typography>
                        <Typography>{training === null ? "Formation : " : "Formation : " + training.training }</Typography>
                        <Typography>{course === null ? "Parours : ": "Parcours : "+ course.course}</Typography>
                        {running ?
                            <Typography>Statut : En Cours</Typography>
                            :
                            <Typography>{"Statut : " + lReasons[reason]}</Typography>
                        }
                        <Typography>{financal == null ? "Financement : " : "Financement : " + financal.name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                        {financal === null || financal.pictures.length === 0?
                            null
                            :
                            <CharismaImagesList
                                add={false}
                                pictures={financal.pictures}
                                width={'200px'}
                                grid={3}
                            />
                        }
                        </Grid>
                    </Grid>       
                    <CharismaTutorSpaceMenu
                        studentTrainingId ={lStudentTrainingId}
                    />
                    {page === "book" && training !== null && student !== null?
                            <CharismaStudentTrainingBook
                                trainingId={training.id}
                                studentId={student.id}
                                studentTrainingId={id}
                            />
                            :
                            null                        
                        }
                    {page === "teaching-docs" && training !== null?
                        <CharismaTrainingDocs
                            type="teaching"
                            trainingId={training.id}
                            readOnly={true}
                            title={'Documents de Cours'}
                        />
                        :
                        null                        
                    }
                    {page === "daily-assessments" && student !== null && training !== null ?
                        <CharismaStudentsTrainingAssessmentsList
                            type={0}
                            studentTrainingId={id}
                            studentId={student.id}
                            title={'Bilans Quotidiens'}
                            trainingId={training.id}
                        />
                        :
                        null                        
                    }
                    {page === "monthly-assessments" && student !== null && training !== null ?
                        <CharismaStudentsTrainingAssessmentsList
                            type={1}
                            studentTrainingId={id}
                            studentId={student.id}
                            title={'Bilans Mensuels'}
                            trainingId={training.id}
                        />
                        :
                        null                        
                    }        
                    {page === "board" && student !== null && training !== null ?
                        <CharismaStudentTrainingBoard
                            studentTrainingId={id}
                            studentId={student.id}
                            trainingId={training.id}
                        />
                        :
                        null
                    }            
                    {page === "tutors-doc" && student !== null && training !== null ?
                            <CharismaTrainingDocs
                                trainingId = {training.id}
                                type="tutor"
                                title="Documents des Tuteurs"
                                readOnly={true}
                            />
                        :
                        null
                    }
                    {page === "alerts" && training !== null ?
                        <CharismaAlertsList
                            studentTrainingId={id}
                        />
                        :
                        null
                    }
                    {page === "scheduler" && training !== null && course !== null ?
                        <CharismaScheduler
                            trainingId={training.id}
                            courseId={course.id}
                        />
                        :
                        null
                    }
                </CardContent>                               
            </Card>
            :
            null
         }
         <div>
            {
                student !== null && training !== null && !docAccepted && docsToAccept["tutor"] !== undefined && docsToAccept["tutor"].length > 0?
                <CharismaStudentDocsToAcceptForm
                    docs={docsToAccept["tutor"]} 
                    handleSubmit={handleAcceptDocs}
                    student={student.firstName + " " + student.lastName}
                    training={training.training}
                />
                :
                null
            }         
         </div>         
        </Box>
          
    );
    
}