
import { CharismaApi } from "../CharismaApi.js";

export const CharismaStudentTrainingApi = {
 
    ListTrainings: async function(pStudentId : number){
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listTrainings.php`,
            method:"GET",
            params:{
                studentId:pStudentId
            }
        })
        return lResponse.data;
    },

    ListStudentsForTutor: async function(pTutorId : number){
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listStudentsForTutor.php`,
            method:"GET",
            params:{
                tutorId:pTutorId
            }
        })
        return lResponse.data;
    },

    ListStudents: async function(pTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listStudentsTraining.php`,
            method:"GET",
            params:{
                trainingId: pTrainingId
            }
        })
        return lResponse.data;
    },
    ListTrashedStudents: async function(pTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listTrashedStudents.php`,
            method:"GET",
            params:{
                trainingId: pTrainingId
            }
        })
        return lResponse.data;
    },
    GetStudentTraining: async function(pStudentTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/getStudentTraining.php`,
            method:"GET",
            params:{
                studentTrainingId : pStudentTrainingId
            }
        })
        return lResponse.data;
    },
    ListPeriodEvals: async function(pStartDate : string, pEndDate : string, pStudentTrainingId){

        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listPeriodEvals.php`,
            method:"GET",
            params:{
                startDate : pStartDate,
                endDate : pEndDate,
                studentTrainingId:pStudentTrainingId
            }
        })
        return lResponse.data;
    },
    DeletePeriodEvals: async function(pStartDate : string, pEndDate : string, pStudentTrainingId, pExams : number[], pAssessments : number[]){
        const lDatas = new FormData();

        lDatas.append('studentTrainingId', pStudentTrainingId);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        pAssessments.forEach((lId)=>{
            lDatas.append("assessmentIds[]", lId);    
        });
        pExams.forEach((lId)=>{
            lDatas.append("examIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/deletePeriodEvals.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    CreateStudentTraining: async function(pStudentId:number, pCourseId: number, pTutorId: number, pTrainingId : number, pFinancalId : number, pStartDate : string, pEndDate : string){
        const lDatas = new FormData();
        lDatas.append('studentId', pStudentId);
        lDatas.append('courseId', pCourseId);
        lDatas.append('tutorId', pTutorId);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('financalId', pFinancalId);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/createStudentTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },

    UpdateStudentTraining: async function(pTutorId: number, pCourseId:number, pFinancalId:number, pStartDate:string, pEndDate : string, pReason : number, pId:number){
        const lDatas = new FormData();
        lDatas.append('studentTrainingId', pId);
        lDatas.append('courseId', pCourseId);
        lDatas.append('tutorId', pTutorId);
        lDatas.append('financalId', pFinancalId);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        lDatas.append('reason', pReason);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/updateStudentTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    DeleteStudentTrainings: async function(pId : number []){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("studentTrainingIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/deleteStudentTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    TrashStudentTraining: async function(pId : number){
        const lDatas = new FormData();
        lDatas.append("studentTrainingId", pId);    
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/trashStudentTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    RestoreStudentTrainings: async function(pId : number){
        const lDatas = new FormData();
        lDatas.append("studentTrainingId", lId);    
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/restoreStudentTraining.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    AddDocument: async function(pStudentTrainingId : number, pStudentId : number,  pDocument : File, pType : string, pDocType : string, pTrainingId:number){
        const lDatas = new FormData();
        lDatas.append('studentTrainingId', pStudentTrainingId);
        lDatas.append('studentId', pStudentId);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('file', pDocument);
        lDatas.append('type', pType);
        lDatas.append('docType', pDocType);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/addDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateAccepted: async function(pStudentTrainingId:number){
        const lDatas = new FormData();
        lDatas.append("studentTrainingId", pStudentTrainingId);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/updateDocumentAccepted.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    UpdateValidity: async function(pDocumentId:number, pValid : number, pStudentTrainingId:number, $pStudentId : number, $pTrainingId: number){
        const lDatas = new FormData();
        lDatas.append("documentId", pDocumentId);
        lDatas.append("studentTrainingId", pStudentTrainingId);
        lDatas.append("valid", pValid)
        lDatas.append("studentId", $pStudentId)
        lDatas.append("trainingId", $pTrainingId)
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/updateDocumentValidity.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    SendSubscribeReminder: async function(pStudentId:number, pTrainingId : number, pStudentTrainingId : number){
        const lDatas = new FormData();
        lDatas.append("studentId", pStudentId);
        lDatas.append("trainingId", pTrainingId);
        lDatas.append("studentTrainingId", pStudentTrainingId)
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/sendSubscribeReminder.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;  
    },
    ListDocument: async function(pStudentTraining:number, pType:string){
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listDocuments.php`,
            method:"GET",
            params:{
                studentTrainingId:pStudentTraining,
                type: pType
            }
        })
        return lResponse.data;
    }, 
    DeleteDocuments: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("documentIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/deleteDocuments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    CreateAlert: async function(pSubject: string, pAlert : string, pStudentTrainingId:number){
        const lDatas = new FormData();
        lDatas.append("subject", pSubject);
        lDatas.append("alert", pAlert);
        lDatas.append("studentTrainingId", pStudentTrainingId)
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/createAlert.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;          
    },
    ListAlerts: async function(pStudentTraining:number){
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listAlerts.php`,
            method:"GET",
            params:{
                studentTrainingId:pStudentTraining
            }
        })
        return lResponse.data;
    },  
    DeleteAlerts: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("alertIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/deleteAlerts.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    CreateAbsence: async function(pSubject: string, pFromDate : string, pToDate : string, pStudentId: number, pStudentTrainingId:number, pFile : File){
        const lDatas = new FormData();
        lDatas.append("subject", pSubject);
        lDatas.append("fromDate", pFromDate);
        lDatas.append("toDate", pToDate);
        lDatas.append("studentTrainingId", pStudentTrainingId);
        lDatas.append("studentId", pStudentId);
        lDatas.append("file", pFile);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/createAbsence.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;          
    },
    ListAbsences: async function(pStudentTraining:number){
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/listAbsences.php`,
            method:"GET",
            params:{
                studentTrainingId:pStudentTraining
            }
        })
        return lResponse.data;
    },  
    DeleteAbsences: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("absenceIds[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/deleteAbsences.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    DownloadZip: async function(pStudentTrainingId : number, pStudentId : number, pType : string, pStudentName : string){
        const lDatas = new FormData();
        lDatas.append("studentTrainingId", pStudentTrainingId);
        lDatas.append("studentId", pStudentId);
        lDatas.append("type", pType);
        lDatas.append("studentName", pStudentName);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/downloadDocuments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ImportStudents: async function(pFile : File, pTrainingId : number){
        const lDatas = new FormData();
        lDatas.append('file', pFile);
        lDatas.append('trainingId', pTrainingId);
        const lResponse = await CharismaApi.request({
            url:`/studentTrainings/import.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },     
}