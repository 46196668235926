import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


import { ValidateString } from '../utils/Validator.tsx';

export default function CharismaEvaluationTemplateSectionForm(props){
    
    const [name, setName] = React.useState(props.name !== undefined ? props.name : "");
    const [comment, setComment] = React.useState(props.comment !== undefined ? props.comment : "");
    const [nError, setNError] = React.useState(0);
    const [wait, setWait] = React.useState(false);

    
    function handleSubmit(){
        const lValidName: boolean = name.trim().length > 0;
        if(!lValidName){
            setNError(1);
        }
        else if(lValidName && props.id === undefined){
            setWait(true);
            props.handleSubmit(name, comment, props.parentId);
        }
        else if(lValidName){
            setWait(true);
            props.handleSubmit(name, comment, props.id);
        }
        
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification d\'une section'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="type"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        setNError(0);
                    }}
                /> 
                <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    multiline
                    rows={5}
                    label="Commentaire"
                    name="comment"
                    value={comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComment(event.target.value)
                    }}
                />
                {wait ?
                    <Alert severity="info">{props.id===undefined?"Création en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}