import * as React from 'react';



import CharismaTrainingDocs from '../training/trainingDocs.tsx'
import CharismaStudentTrainingDocs from './studentTrainingDocs.tsx'


import Grid from '@mui/material/Grid'


import { CharismaUserContext } from '../context.tsx';

export default function CharismaStudentTrainingBook(props){
    

    

    const lCurrentUser = React.useContext(CharismaUserContext);

    


    return(        
        <Grid container spacing={4}>            
            <Grid item xs={12} sm={4}>
                <CharismaStudentTrainingDocs
                    type="personal"
                    studentTrainingId={props.studentTrainingId}
                    studentId={props.studentId}
                    readOnly={lCurrentUser.profile !== 0}
                    studentName = {props.studentName}
                    title={'Documents Personnels'}
                    trainingId = {props.trainingId}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <CharismaStudentTrainingDocs
                    type="evals"
                    studentTrainingId={props.studentTrainingId}
                    studentId={props.studentId}
                    readOnly={lCurrentUser.profile !== 0}
                    userProfile = {lCurrentUser.profile}
                    studentName = {props.studentName}
                    title={'Evaluations'}
                    trainingId = {props.trainingId}
                />
            </Grid>                    
            <Grid item xs={12} sm={4}>
                <CharismaTrainingDocs
                    type="book"
                    trainingId={props.trainingId}
                    readOnly={true}
                    title={'Documents Administratifs'}
                />
            </Grid>
            {lCurrentUser.profile === 0 ?
                <Grid item xs={12} sm={8}>
                    <CharismaStudentTrainingDocs
                        type="subscribe"
                        studentTrainingId={props.studentTrainingId}
                        studentId={props.studentId}
                        readOnly={lCurrentUser.profile !== 0}
                        userProfile = {lCurrentUser.profile}
                        studentName = {props.studentName}
                        title={'Documents d\'Inscription'}
                        trainingId = {props.trainingId}
                    />
                </Grid>
                :
                null
            }
                      
        </Grid>       
                          
    );
    
    
}