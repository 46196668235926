import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaAssessmentApi } from '../api/assessments/CharismaAssessmentApi.tsx';
import CharismaAssessmentDateForm from './assessmentDateForm.tsx';
import CharismaStudentTrainingAssessmentForm from './studentTrainingAssessmentPartForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {extractMonth, formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaStudentTrainingAssessmentsList(props){
    
    interface assessment{
        id: number,
        date: string
        type: number
    }
       
    interface item{
        id:number,
        type: number,
        title: string,
        note:number
    }

    const [assessments, setAssessments] = React.useState([]);
    const [create, setCreate] = React.useState(false);    
    const [deleteAssessments, setDeleteAssessments] = React.useState(false);
    const [selectedAssessments, setSelectedAssessments] = React.useState([]);   
    const [currentAssessment, setCurrentAssessment] = React.useState(null);    
    const [partProfile, setPartProfile] = React.useState(0);
   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lStudentTrainingId = props.studentTrainingId;
    const lTrainingId = props.trainingId;
    const lStudentId = props.studentId;
    const lType = props.type;


    React.useEffect(()=>{
        const lResponseAssessments = CharismaAssessmentApi.ListAssessments(lType, lStudentTrainingId)
        lResponseAssessments.then((value)=>{
            if(value.error === 0){
                setAssessments(value.assessments);
            }
        }).catch((error)=>{
            
        })
    }, [lStudentTrainingId, lType])
    
    
          
    function handleCreateAssessment(pDate : string){
        const lResponse = CharismaAssessmentApi.CreateAssessment(lTrainingId, lType, pDate, lStudentTrainingId, lStudentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    let lAssessments = assessments.concat(value.assessment);
                    lAssessments = lAssessments.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setAssessments(lAssessments);
                    setCreate(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    } 
    function handleCancelCreate(){
        setCreate(false);
    }

    function handleDeleteAssessments(pSelectedCourses){
        const lResponse = CharismaAssessmentApi.DeleteAssessments(selectedAssessments)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lAssessments = assessments.filter((lAssessment)=>lIds.indexOf(lAssessment.id) === -1);
                    setAssessments(lAssessments);
                    setDeleteAssessments(false);                  
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }            
    
    function handleSelectedAssessment(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedAssessments.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedAssessments, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(1));
            }
            else if(lIndex === selectedAssessments.length - 1){
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedAssessments.slice(0, lIndex), selectedAssessments.slice(lIndex + 1),);
            }
        }
        setSelectedAssessments(lNewSelected);
    }
    
    function handleSetPresent(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lPresence = 1;
        if(pEvent.target.checked){
            lPresence = 0;
        }
        const lResponse = CharismaAssessmentApi.UpdateAssessmentPresence(lPresence, pId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    const lAssessmentId = value.assessment.id;
                    let lAssessments = assessments.slice();
                    for(let lIndex = 0; lIndex < lAssessments.length; lIndex++){
                        if(lAssessments[lIndex].id === lAssessmentId){
                            lAssessments[lIndex].isPresent = value.assessment.isPresent;
                            setAssessments(lAssessments);
                            break;
                            
                        }
                    }                  
                }
                setCurrentAssessment(null);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })          
    }
            
    
    function handleCancelUpdate(){
        setCurrentAssessment(null);
    }
    
    function handleEditDate(pId : number){
        const lResponse = CharismaAssessmentApi.GetAssessment(pId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    setCurrentAssessment(value.assessment);                      
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })    
    }     
    
    
    function handleUpdateDate(pDate : string, pId :number){
        const lResponse = CharismaAssessmentApi.UpdateAssessmentDate(pDate, pId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    const lAssessmentId = value.assessment.id;
                    let lAssessments = assessments.slice();
                    for(let lIndex = 0; lIndex < lAssessments.length; lIndex++){
                        if(lAssessments[lIndex].id === lAssessmentId){
                            lAssessments[lIndex].date = value.assessment.date;
                            lAssessments = lAssessments.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                            setAssessments(lAssessments);
                            break;
                            
                        }
                    }                  
                }
                setCurrentAssessment(null);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })          
    }
    
    function handleUpdate(pItems : item[], pPartComment : string, pPartId : number, pAssessmentId : number){
        const lResponse = CharismaAssessmentApi.UpdateAssessmentNotes(pItems, pPartComment, pPartId, pAssessmentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    const lId = value.assessment.assessmentId;
                    const lPartId = value.assessment.partId;
                    let lAssessments = assessments.slice();
                    for(let lIndex= 0; lIndex < lAssessments.length ;lIndex++){
                        let lAssessment = lAssessments[lIndex];
                        if(lAssessment.id === lId){
                            let lParts = lAssessment.parts;
                            for(let lIndexPart=0; lIndexPart < lParts.length; lIndexPart++){
                                if(lParts[lIndexPart].id === lPartId){
                                    lParts[lIndexPart].isSigned = 1;
                                    if(value.stat !== null){
                                        lParts[lIndexPart].stat = value.stat;    
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    setAssessments(lAssessments);
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })  
        setCurrentAssessment(null);
    }
    
    function handleEdit(pId : number, pProfile : number){
        const lResponse = CharismaAssessmentApi.GetAssessment(pId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    setCurrentAssessment(value.assessment);       
                    setPartProfile(pProfile);                      
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })         
    }
    
    function handleCancelEdit(){
        setCurrentAssessment(null);
        setPartProfile(0);
    }
    
    function isPartSigned(pAssessment: assessment, pProfile : number){
        const lParts = pAssessment.parts;
        let lIsSigned = false;
        if(lParts === undefined){
            return false;
        }
        lParts.forEach((lPart)=>{
            if(lPart.profile === pProfile){
                lIsSigned = lPart.isSigned === 1;                
            }
        });
        return lIsSigned;        
    }
    
    function getPartColor(pAssessment: assessment, pProfile : number){
        const lParts = pAssessment.parts;        
        let lColor = pAssessment.isPresent ? "" : "gray";
        if(lParts === undefined || !pAssessment.isPresent){
            return lColor;
        }
        lParts.forEach((lPart)=>{
            if(lPart.profile === pProfile){
                lPart.stat > 2 ? lColor="green" : lPart.stat ===2 ? lColor="orange" : lPart.stat !== 0 ? lColor="red" : lColor="";  
                              
            }
        });
        
        return lColor;        
    }
    
    function createButton(pAssessment : assessment, pProfile : number){
        const lIsSigned = isPartSigned(pAssessment, pProfile);        
        if(lIsSigned && pAssessment.type !== 0){
            return(
                 <Button
                    onClick={()=>handleEdit(pAssessment.id, pProfile)}
                >
                    Voir
                </Button>               
            );
        }
        else{
            return(
                 <Button
                    onClick={()=>handleEdit(pAssessment.id, pProfile)}
                >
                    {(lCurrentUser.profile !== pProfile) && (pProfile !== 1 || lCurrentUser.profile !==0)? "Voir" : "Editer"}
                </Button>               
            );            
        }
    }

    function createDailyAssessmentsTable(pAssessments : assessment[], pSelected : number[]){
        return(
            pAssessments.map((assessment)=>(
                <TableRow key={assessment.id} sx={{backgroundColor:getPartColor(assessment, 1)}}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(assessment.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedAssessment(event, assessment.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell align='center'>
                        
                        {formatDate(assessment.date)}
                        {lReadWrite ?
                            <IconButton onClick={()=>handleEditDate(assessment.id)}>
                                <EditIcon/>
                            </IconButton>
                            :
                            null
                        }
                    </TableCell>       
                    <TableCell>
                        <Checkbox
                            checked={!assessment.isPresent}
                            disabled={!lReadWrite}
                            onClick={                                    
                                (event)=>handleSetPresent(event, assessment.id)
                                }
                        />
                        {
                            !assessment.isPresent && assessment.attach !== null && assessment.attach.length > 0?
                            <Link to={assessment.attach} target="_blank" rel="noopener noreferrer" >
                                <AttachFileIcon/>
                            </Link>
                            :
                            null
                        }
                    </TableCell>

                    <TableCell
                        sx={{background:getPartColor(assessment,2)}}
                        align='center'
                    >
                        {assessment.parts[0].signatoryName}
                    </TableCell>

                    <TableCell
                        align='center'
                    >
                       {createButton(assessment, 1)}
                    </TableCell>

                    <TableCell>
                        <IconButton component={Link} to={global.api + "/assessments/printAssessment.php?id="+assessment.id} target="_blank" rel="noopener noreferrer">
                            <PictureAsPdfIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                 
            ))
        )
    }
    
    function createAssessmentsTable(pAssessments : assessment[], pSelected : number[]){
        return(
            pAssessments.map((assessment)=>(
                <TableRow key={assessment.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(assessment.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedAssessment(event, assessment.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell align='center'>
                        
                        {lType === 1 ? 
                            extractMonth(assessment.date)
                            :
                            formatDate(assessment.date)
                        }
                        {lReadWrite ?
                            <IconButton onClick={()=>handleEditDate(assessment.id)}>
                                <EditIcon/>
                            </IconButton>
                            :
                            null
                        }
                    </TableCell>       
                    {lType === 0 ?
                        <TableCell>
                            <Checkbox
                                checked={!assessment.isPresent}
                                disabled={!lReadWrite}
                                onClick={                                    
                                    (event)=>handleSetPresent(event, assessment.id)
                                    }
                            />
                            {
                                !assessment.isPresent && assessment.attach !== null && assessment.attach.length > 0?
                                <Link to={assessment.attach} target="_blank" rel="noopener noreferrer" >
                                    <AttachFileIcon/>
                                </Link>
                                :
                                null
                            }
                        </TableCell>
                        :
                        null       
                    } 
                    {
                        lType === 0 ?
                            <TableCell
                                sx={{background:getPartColor(assessment,2)}}
                                align='center'
                            >
                                {assessment.parts[0].signatoryName}
                            </TableCell>
                        :
                        null
                    }                    
                    {                        
                        lType === 0 || lType === 1 || lType === 4 || lType === 5?
                            <TableCell
                                sx={{background:getPartColor(assessment,1)}}
                                align='center'
                            >
                               {createButton(assessment, 1)}
                            </TableCell>
                        :
                        null                     
                    }
                    {
                        lType === 1 ?
                            <TableCell
                                sx={{background:getPartColor(assessment,2)}}
                                align='center'
                            >
                                {createButton(assessment, 2)}
                            </TableCell>
                        :
                        null                    
                    }
                    <TableCell>
                        <IconButton component={Link} to={global.api + "/assessments/printAssessment.php?id="+assessment.id} target="_blank" rel="noopener noreferrer">
                            <PictureAsPdfIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader
                            title={props.title}
                            action={
                                <div>
                                {lReadWrite?
                                <div>
                                <IconButton onClick={()=>setCreate(true)}>
                                    <AddIcon/>
                                </IconButton>
                                {selectedAssessments.length > 0?
                                    <IconButton onClick={()=>setDeleteAssessments(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                                :
                                null
                                }                                
                                </div>
                            }
                        />
                        <CardContent>
                            <TableContainer overflowX='auto'>
                            <Table>                               
                                <TableHead>
                                    <TableRow>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                                </TableCell>
                                              :
                                              null
                                        }
                                        <TableCell sx={{minWidth:100}}align='center'>Date</TableCell>    
                                        {lType === 0 ?
                                            <TableCell>
                                                Absent
                                            </TableCell>
                                            :
                                            null
                                        }
                                        {lType === 0 ?
                                            <TableCell align='center'>
                                                Formateur
                                            </TableCell>
                                            :
                                            null                                            
                                        }                                        
                                        {lType === 0 || lType === 4 || lType === 5 ?
                                            <TableCell>
                                            </TableCell>
                                            :
                                            null
                                        }
                                        {lType === 1 ?
                                            <TableCell align='center'>
                                                Formation
                                            </TableCell>
                                            :
                                            null
                                        }
                                        {lType === 1 ?
                                            <TableCell align='center'>
                                                Tutorat
                                            </TableCell>
                                            :
                                            null
                                        }      
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { lType === 0 ?
                                    createDailyAssessmentsTable(assessments, selectedAssessments)
                                    :
                                    createAssessmentsTable(assessments, selectedAssessments)
                                }
                                </TableBody>
                            </Table>  
                            </TableContainer>                          
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {create ?
                <CharismaAssessmentDateForm
                    title={lType === 4 ? "Visites Tuteurs" : lType === 5 ? "Visite de Régluation" : "Ajout d'un bilan"}
                    handleSubmit={handleCreateAssessment}
                    handleCancel={handleCancelCreate}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                />
                :
                null    
            }    
            {
                currentAssessment !== null ?
                    partProfile === 0?
                        <CharismaAssessmentDateForm
                            date={currentAssessment.date}
                            id={currentAssessment.id}
                            minDate={props.startDate}
                            maxDate={props.endDate}
                            handleSubmit={handleUpdateDate}
                            handleCancel={handleCancelUpdate}
                        />
                        :                    
                        <CharismaStudentTrainingAssessmentForm
                            assessment={currentAssessment}
                            profile={partProfile}
                            handleCancel={handleCancelEdit}
                            handleSubmit={handleUpdate}
                            type={lType}
                            readOnly = {(lCurrentUser.profile !== partProfile) && 
                                        (partProfile !== 1 | lCurrentUser.profile !==0)}
                        />
                    :
                    null
            }
            
            <Dialog
                open={deleteAssessments}
            >
                <DialogTitle>Suppression de bilans</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les bilans sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteAssessments(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteAssessments}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                     
        </Box>      
    );
    
    
}