import * as React from 'react';


import Box from '@mui/material/Box'


import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';

import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import CharismaStudentTrainingForm from './studentTrainingForm.tsx';
import CharismaImagesList from '../components/imagesList.tsx';
import CharismaStudentTrainingMenu from './studentTrainingMenu.tsx'
import CharismaStudentTrainingBook from './studentTrainingBook.tsx'
import CharismaAlertsList from './alertsList.tsx'
import CharismaAbsencesList from './absencesList.tsx'
import CharismaStudentTrainingAssessmentsList from './studentTrainingAssessmentsList.tsx';
import CharismaStudentTrainingBoard from './studentTrainingBoard.tsx'
import CharismaTrainingDocs from '../training/trainingDocs.tsx'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';

import { CharismaUserContext } from '../context.tsx';
import { formatDate } from '../utils.tsx';



export default function CharismaStudentTrainingSpace(props){
    
        
    const [id, setId] = React.useState(props.id);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [reason, setReason] = React.useState(0);
    const [student, setStudent] = React.useState(null);
    const [training, setTraining] = React.useState(null);
    const [financal, setFinancal] = React.useState(null);
    const [course, setCourse] = React.useState(null);
    const [tutor, setTutor] = React.useState(null);
    const [deleteStudentTraining, setDeleteStudentTraining] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(0);
    
    React.useEffect(()=>{
        const lResponse = CharismaStudentTrainingApi.GetStudentTraining(props.studentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setId(value.studentTraining.id);
                setStartDate(value.studentTraining.startDate);
                setEndDate(value.studentTraining.endDate);
                setReason(value.studentTraining.reason === null ? 0 : value.studentTraining.reason);
                setStudent(value.studentTraining.student);
                setFinancal(value.studentTraining.financal);
                setTutor(value.studentTraining.tutor);  
                setTraining(value.studentTraining.training);
                setCourse(value.studentTraining.course)
            }
        }).catch((error)=>{
            
        })

    }, [props.studentTrainingId])
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    
    const lReasons=["En Cours", "Certifié", "Abandon", "Echec"];

    function handleCancelUpdate(){
        setUpdate(false);
    }
    
    function handleSubmit(pTutorId: number, pCourseId: number, pFinancalId : number,  pStartDate : string, pEndDate : string, pReason : number){
        const lResponse = CharismaStudentTrainingApi.UpdateStudentTraining(pTutorId, pCourseId, pFinancalId, pStartDate, pEndDate, pReason, id);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setId(value.studentTraining.id);
                setStartDate(value.studentTraining.startDate);
                setEndDate(value.studentTraining.endDate);
                setReason(value.studentTraining.reason);
                setStudent(value.studentTraining.student);
                setFinancal(value.studentTraining.financal);
                setTutor(value.studentTraining.tutor);  
                setTraining(value.studentTraining.training);
                setCourse(value.studentTraining.course)
                setReason(value.studentTraining.reason === null ? 0 : value.studentTraining.reason);
                props.update(value.studentTraining);
                setUpdate(false);
            }
        }).catch((error)=>{
            
        })
    }    
    
    function handleTrash(){
        const lResponse = CharismaStudentTrainingApi.TrashStudentTraining(id);
        lResponse.then((value)=>{
            if(value.error === 0 && value.id !== null){
                setDeleteStudentTraining(false);
                props.remove(value.id);
            }
        }).catch((error)=>{
            
        })
    }
    
    return(
        <Box>
            {student !== null?
                <Card>
                    <CardHeader                   
                        avatar={
                            props.showInfo === undefined || props.showInfo && student.avatar !== null ?
                            
                            <Avatar
                                src={student.avatar}
                            />
                            :
                            props.showInfo === undefined || props.showInfo ?
                            <Avatar>
                                {student.firstName[0] + student.lastName[0]}
                            </Avatar>
                            :
                            null
                        }
                        action={
                            lCurrentUser.profile === 0 ?
                            <div>                                    
                                <IconButton onClick={()=>setUpdate(true)}>
                                    <EditIcon/>
                                </IconButton>
                                
                                <IconButton onClick={()=>setDeleteStudentTraining(true)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </div>
                            :
                            null
                        }  
                        title={
                            props.showInfo === undefined || props.showInfo ?
                            <Box>
                                <Typography>{student.firstName + ' ' + student.lastName}</Typography>
                                <Typography>{student.mail}</Typography>
                                <Typography>{student.phone}</Typography>  
                            </Box>
                            :
                            null
                        } 
                    />
                    
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={3}>                        
                            <Typography>{"Date d'entrée : " + formatDate(startDate)}</Typography>
                            <Typography>{"Tuteur : " + tutor.firstName + ' ' + tutor.lastName}</Typography>
                            <Typography>{"Parcours : " + course.course}</Typography>
                            <Typography>{"Statut : " + lReasons[reason]}</Typography>
                            
                            <Typography>{"Financement : " + financal.name}</Typography>
                            {reason > 0 ?
                                <Typography>{"Date de fin : " + formatDate(endDate)}</Typography>
                                :
                                null
                             }
                            </Grid>
                            <Grid item xs={12} sm={9}>
                            {financal.pictures.length === 0?
                                null
                                :
                                <CharismaImagesList
                                    add={false}
                                    pictures={financal.pictures}
                                    width={'200px'}
                                    grid={3}
                                />
                            }
                            </Grid>
                        </Grid>       
                        <CharismaStudentTrainingMenu
                            handleMenu={setCurrentPage}
                        />             
                        {currentPage === 0 ?
                            <CharismaStudentTrainingBook
                                trainingId={training.id}
                                studentId={student.id}
                                studentName={student.firstName +'_' + student.lastName}
                                studentTrainingId={id}
                            />
                            :
                            null                        
                        }
                        {currentPage === 1 ?
                            <CharismaTrainingDocs
                                type="teaching"
                                trainingId={training.id}
                                readOnly={true}
                                title={'Documents de Cours'}
                            />
                            :
                            null                        
                        }
                        {currentPage === 2 ?
                            <CharismaStudentTrainingBoard
                                studentTrainingId={id}
                                studentId={student.id}
                                trainingId={training.id}
                                trainingType = {training.type}
                                studentName={student.firstName +'_' + student.lastName}
                            />
                            :
                            null                        
                        }                        
                        {currentPage === 3 ?
                            <CharismaStudentTrainingAssessmentsList
                                type={0}
                                studentTrainingId={id}
                                studentId={student.id}
                                title={'Bilans Quotidiens'}
                                trainingId={training.id}
                            />
                            :
                            null                        
                        }
                        {currentPage === 4 ?
                            <CharismaStudentTrainingAssessmentsList
                                type={1}
                                studentTrainingId={id}
                                studentId={student.id}
                                title={'Bilans Mensuels'}
                                trainingId={training.id}
                            />
                            :
                            null                        
                        }
                        {currentPage === 5 ?
                            <CharismaAlertsList
                                studentTrainingId={id}
                             />
                             :
                             null
                        }                        
                        {currentPage === 6 ?
                            <CharismaStudentTrainingAssessmentsList
                                type={4}
                                studentTrainingId={id}
                                studentId={student.id}
                                title={'Visites Tuteurs'}
                                trainingId={training.id}
                            />
                            :
                            null                        
                        }
                        {currentPage === 7 ?
                            <CharismaStudentTrainingAssessmentsList
                                type={5}
                                studentTrainingId={id}
                                studentId={student.id}
                                title={'Visites de Régulations'}
                                trainingId={training.id}
                            />
                            :
                            null                        
                        }     
                        {currentPage === 8 ?
                            <CharismaAbsencesList
                                studentTrainingId={id}
                                studentId={student.id}
                             />
                             :
                             null
                        }
                    </CardContent>                                 
                </Card>
            :
                null
            }
            {update ?
                <CharismaStudentTrainingForm
                    courses={props.courses}
                    tutors={props.tutors}
                    financals={props.financals}
                    tutor={tutor}
                    financal={financal.id}
                    startDate={startDate}
                    endDate={endDate}
                    reason={reason}
                    course={course.id}
                    id={props.studentTrainingId}
                    handleCancel={handleCancelUpdate}
                    handleSubmit={handleSubmit}
                />
                :
                null    
            }    
            {student !== null?
            <Dialog
                open={deleteStudentTraining}
            >
                <DialogTitle>{'Supprimer un utilisateur'}</DialogTitle>
                <DialogContent>
                    <Typography>{'Etes-vous sûr de vouloir supprimer ' + student.firstName + ' ' + student.lastName + ' de la formation'} </Typography>                                        
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteStudentTraining(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>handleTrash()}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            :
            null
            }
        </Box>      
    );
    
}